import 'core-js/es';
import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { ErrorPage } from '@btdt/design-system';
import '../btdt.scss';
import './errorPages.scss';

function App(): ReactElement {
  const error404Content = (
    <>
      <h1>Uh-oh, we can't find what you're looking for.</h1>
      <p>The page you are looking for doesn't exist or has been moved.</p>
      <a href="/">Back to the homepage</a>
    </>
  );
  return <ErrorPage responseCode={404} errorContent={error404Content} />;
}

ReactDOM.render(<App />, document.getElementById('error-root'));
