import React, { ReactElement } from 'react';
import './errorPage.scss';

type Props = {
  responseCode: 400 | 403 | 404 | 500;
  errorContent?: JSX.Element;
};

export default function ErrorPage({ responseCode, errorContent }: Props): ReactElement {
  const firstNumber = responseCode.toString().charAt(0);
  const lastNumber = responseCode.toString().charAt(2);
  return (
    <div className="ds-error-page">
      <div className="ds-error-page__code">
        <span className="ds-error-page__number ds-error-page__number--first">{firstNumber}</span>
        <div className="ds-error-page__zero" />
        <span className="ds-error-page__number ds-error-page__number--third">{lastNumber}</span>
      </div>
      {errorContent && <div className="ds-error-page__content">{errorContent}</div>}
    </div>
  );
}
